import testmember from "../../../../assets/people/members/test.png";

const male = "https://wechat.static.qingyou.ren/qingyou-website/xcx.jpg";
const female = "https://wechat.static.qingyou.ren/qingyou-website/qxy.jpg";

export const categoryRange = [
  "指导老师",
  "负责人",
  "创始团队",
  "第一届",
  "第二届",
  "第三届",
  "第四届",
  "第五届",
  "第六届",
  "第七届",
];

export type CategoryRangeType =
  | "指导老师"
  | "负责人"
  | "创始团队"
  | "第一届"
  | "第二届"
  | "第三届"
  | "第四届"
  | "第五届"
  | "第六届"
  | "第七届";

export const membersList: Array<{
  avatar: string;
  name: string;
  description: string;
  grade: CategoryRangeType;
}> = [
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/顾亦然.jpeg",
    name: "顾亦然",
    description: "信息办",
    grade: "指导老师",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/李晓华.jpg",
    name: "李晓华",
    description: "团委",
    grade: "指导老师",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/严宇倞.jpeg",
    name: "严宇倞",
    description: "信息办",
    grade: "指导老师",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/秦昌建.png",
    name: "秦昌建",
    description: "团委",
    grade: "指导老师",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/guyi.jpg",
    name: "顾怡",
    description: "创始人，信息办",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/江子玮.jpeg",
    name: "江子玮",
    description: "技术负责人，第零届负责人，微派",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/张楚月.jpeg",
    name: "张楚月",
    description: "视觉负责人，浙江大学",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/谭康威.jpeg",
    name: "谭康威",
    description: "产品负责人，第一届负责人，腾讯游戏",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/彭泽凡.jpeg",
    name: "彭泽凡",
    description: "初期前端负责人，字节跳动",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/宁泓皓.jpeg",
    name: "宁泓皓",
    description: "初期前端负责人，字节跳动",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/朱晓峰.jpeg",
    name: "朱晓峰",
    description: "初期后端负责人，数美科技",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/黄兴源.jpeg",
    name: "黄兴源",
    description: "第二届负责人，字节跳动",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/黄艺玲.jpeg",
    name: "黄艺玲",
    description: "第三届负责人，南京大学",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/柳黎轲.jpg",
    name: "柳黎轲",
    description: "第四届负责人，AutoX",
    grade: "负责人",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/程凡恺.jpg",
    name: "程凡恺",
    description: "第四届负责人，新南威尔士大学",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/栗浩铭.jpg",
    name: "栗浩铭",
    description: "第五届负责人",
    grade: "负责人",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/周荣军.png",
    name: "周荣军",
    description: "第六届负责人",
    grade: "负责人",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/顾怡.jpeg",
    name: "顾怡",
    description: "信息办",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/江子玮.jpeg",
    name: "江子玮",
    description: "微派",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/张楚月.jpeg",
    name: "张楚月",
    description: "浙江大学",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/谭康威.jpeg",
    name: "谭康威",
    description: "腾讯游戏",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/彭泽凡.jpeg",
    name: "彭泽凡",
    description: "字节跳动",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/宁泓皓.jpeg",
    name: "宁泓皓",
    description: "字节跳动",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/朱晓峰.jpeg",
    name: "朱晓峰",
    description: "数美科技",
    grade: "创始团队",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/0/唐子惠.jpeg",
    name: "唐子惠",
    description: "上海班图",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/0/%E9%A1%BE%E5%88%98%E5%BB%BA.jpeg",
    name: "顾刘健",
    description: "北京瑞莱智慧科技有限公司",
    grade: "创始团队",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/0/李赫.png",
    name: "李赫",
    description: "-",
    grade: "创始团队",
  },
  {
    avatar: male,
    name: "郑致远",
    description: "微软",
    grade: "创始团队",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/0/吴爱天.jpeg",
    name: "吴爱天",
    description: "阿里巴巴",
    grade: "创始团队",
  },
  {
    avatar: male,
    name: "王悉宇",
    description: "阿里云",
    grade: "创始团队",
  },
  {
    avatar: male,
    name: "周捷",
    description: "安恒",
    grade: "创始团队",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/黄兴源.jpeg",
    name: "黄兴源",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/赵明睿.jpeg",
    name: "赵明睿",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/刘源.jpeg",
    name: "刘源",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/1/史朝夕.jpg?a=2",
    name: "史朝夕",
    description: "纽约大学",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/欧阳宇翔.jpeg",
    name: "欧阳宇翔",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/邹昊.jpeg",
    name: "邹昊",
    description: "腾讯",
    grade: "第一届",
  },
  {
    avatar: male,
    name: "朱逸尘",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/柏超宇.jpeg",
    name: "柏超宇",
    description: "国家电网",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/肖雨点.jpeg",
    name: "肖雨点",
    description: "保密",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/安泓烨.jpeg",
    name: "安泓烨",
    description: "美团点评",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/杨丛铭.jpeg",
    name: "杨丛铭",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/林禹辰.jpeg",
    name: "林禹辰",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar: male,
    name: "赵恒远",
    description: "新加坡国立大学",
    grade: "第一届",
  },
  {
    avatar: male,
    name: "谭嘉晖",
    description: "字节跳动",
    grade: "第一届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/1/鲍可.jpeg",
    name: "鲍可",
    description: "-",
    grade: "第一届",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/黄艺玲.jpeg",
    name: "黄艺玲",
    description: "南京大学",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/陈志帆.jpeg",
    name: "陈志帆",
    description: "字节跳动",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/奚伟豪.jpg",
    name: "奚伟豪",
    description: "美团",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/高凡.png",
    name: "高凡",
    description: "阿里巴巴",
    grade: "第二届",
  },
  {
    avatar: male,
    name: "仲晨宇",
    description: "南京邮电大学",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/陈凯森.jpeg",
    name: "陈凯森",
    description: "腾讯",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/周灿.jpeg",
    name: "周灿",
    description: "腾讯",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/梁耀文.jpg",
    name: "梁耀文",
    description: "Autox",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/皮康龙.jpeg",
    name: "皮康龙",
    description: "SHEIN",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/周馨.jpg",
    name: "周馨",
    description: "明基",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/李林睿.jpeg",
    name: "李林睿",
    description: "网易",
    grade: "第二届",
  },
  {
    avatar: male,
    name: "顾博翰",
    description: "-",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/黄敏志.jpeg",
    name: "黄敏志",
    description: "南京邮电大学",
    grade: "第二届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/2/季嘉澎.jpeg",
    name: "季嘉澎",
    description: "上海邮电设计咨询研究院有限公司",
    grade: "第二届",
  },
  {
    avatar: female,
    name: "叶涵",
    description: "南京师范大学",
    grade: "第二届",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/柳黎轲.jpg",
    name: "柳黎轲",
    description: "AutoX",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/程凡恺.jpg",
    name: "程凡恺",
    description: "新南威尔士大学",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/韦庆斌.jpg",
    name: "韦庆斌",
    description: "上海联通",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/王宏州.jpg",
    name: "王宏州",
    description: "东南大学",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/杨亚鹏.jpg",
    name: "杨亚鹏",
    description: "南京邮电大学",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/金诚.jpg",
    name: "金诚",
    description: "东南大学",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/孙方杰.jpg",
    name: "孙方杰",
    description: "字节跳动",
    grade: "第三届",
  },
  {
    avatar: male,
    name: "潘承安",
    description: "南京邮电大学",
    grade: "第三届",
  },
  {
    avatar: female,
    name: "胡慧云",
    description: "北京邮电大学",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/陈炫栋.jpg",
    name: "陈炫栋",
    description: "东南大学",
    grade: "第三届",
  },
  {
    avatar: female,
    name: "吕鸿妤",
    description: "东南大学",
    grade: "第三届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/3/钱嘉.jpg",
    name: "钱嘉",
    description: "-",
    grade: "第三届",
  },
  {
    avatar:
      "https://wechat.static.qingyou.ren/qingyou-website/leaders/栗浩铭.jpg",
    name: "栗浩铭",
    description: "小红书公司",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/夏昊南.jpg",
    name: "夏昊南",
    description: "阿里淘天集团",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/孟令东.png",
    name: "孟令东",
    description: "西安交通大学",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/顾文韬.png",
    name: "顾文韬",
    description: "青颖飞帆",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/王凌奕.png",
    name: "王凌奕",
    description: "弗吉尼亚理工大学",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/孙晨曦.jpg",
    name: "孙晨曦",
    description: "字节跳动",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/朱文灏.jpg",
    name: "朱文灏",
    description: "前端开发",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/谢铭佳.jpg",
    name: "谢铭佳",
    description: "东南大学",
    grade: "第四届",
  },
  {
    avatar: male,
    name: "金煜洲",
    description: "-",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/于思远.png",
    name: "于思远",
    description: "腾讯",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/倪倩怡.jpg",
    name: "倪倩怡",
    description: "-",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/廖淑娟.jpg",
    name: "廖淑娟",
    description: "-",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/房稼骐.jpg",
    name: "房稼骐",
    description: "自由插画师",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/黄天欣.png",
    name: "黄天欣",
    description: "中国电信",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/4/刘馨月.png",
    name: "刘馨月",
    description: "徐州移动",
    grade: "第四届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/周荣军.png",
    name: "周荣军",
    description: "后端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/李正楠.jpg",
    name: "李正楠",
    description: "前端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/孙晟昊.png",
    name: "孙晟昊",
    description: "视频制作",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/华剑椿.jpg",
    name: "华剑椿",
    description: "运维",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/郑先明.jpg",
    name: "郑先明",
    description: "前端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/赵宇豪.png",
    name: "赵宇豪",
    description: "前端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/郑子熠.jpg",
    name: "郑子熠",
    description: "后端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/刘长杰.png",
    name: "刘长杰",
    description: "后端开发",
    grade: "第五届",
  },

  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/盘贻桓.png",
    name: "盘贻桓",
    description: "后端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/冯一轩.png",
    name: "冯一轩",
    description: "后端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/李林涛.jpg",
    name: "李林涛",
    description: "后端开发",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/文超航.png",
    name: "文超航",
    description: "后端开发",
    grade: "第五届",
  },

  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/刘雨.jpg",
    name: "刘雨",
    description: "画师",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/刘昊莹.jpg",
    name: "刘昊莹",
    description: "画师",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/李文婧.jpg",
    name: "李文婧",
    description: "画师",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/窦媚琳.jpg",
    name: "窦媚琳",
    description: "画师",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/卜家祺.jpg",
    name: "卜家祺",
    description: "视频制作",
    grade: "第五届",
  },

  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/郑娴.jpg",
    name: "郑娴",
    description: "海南电网",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/李云舒.jpg",
    name: "李云舒",
    description: "新媒体运营",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/5/吴天驹.png",
    name: "吴天驹",
    description: "项目管理",
    grade: "第五届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/龙夫江.png",
    name: "龙夫江",
    description: "前端开发",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/梁少峻.png",
    name: "梁少峻",
    description: "前端开发",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/杨宇豪.png",
    name: "杨宇豪",
    description: "后端开发",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/刘卓恒.png",
    name: "刘卓恒",
    description: "后端开发",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/李帅.png",
    name: "李帅",
    description: "后端开发",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/李佳兴.png",
    name: "李佳兴",
    description: "后端开发",
    grade: "第六届",
  },
  {
    avatar: male,
    name: "吴家齐",
    description: "运维",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/张钊瑜.png",
    name: "张钊瑜",
    description: "画师",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/吴桢臻.png",
    name: "吴桢臻",
    description: "画师",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/陈骁睿.png",
    name: "陈骁睿",
    description: "画师",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/金航.png",
    name: "金航",
    description: "视频制作",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/顾洁.png",
    name: "顾洁",
    description: "运营",
    grade: "第六届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/6/庄熙媛.png",
    name: "庄熙媛",
    description: "新媒体运营",
    grade: "第六届",
  },
  
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/胡天宇.png",
    name: "胡天宇",
    description: "前端开发",
    grade: "第七届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/钟泽安.png",
    name: "钟泽安",
    description: "前端开发",
    grade: "第七届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/姚思飞.png",
    name: "姚思飞",
    description: "前端开发",
    grade: "第七届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/胡翊恒.jpg",
    name: "胡翊恒",
    description: "后端开发",
    grade: "第七届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/周正.jpg",
    name: "周正",
    description: "画师",
    grade: "第七届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/卜庆鑫.jpg",
    name: "卜庆鑫",
    description: "ui设计",
    grade: "第七届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/唐可欣.png",
    name: "唐可欣",
    description: "新媒体运营",
    grade: "第七届",
  },
  {
    avatar: "https://wechat.static.qingyou.ren/qingyou-website/7/王乐乐.png",
    name: "王乐乐",
    description: "新媒体运营",
    grade: "第七届",
  },
];
