import React, { useEffect, useState } from "react";

import "./index.scss";

function MemberSelector(props: {
  range: Array<string>;
  onChange?: Function;
  indexSelected?: number;
}) {
  const [value, setValue] = useState(0);

  const handleChangeIndex = (e: React.MouseEvent) => {
    const currentSelected = (e.target as HTMLLIElement).dataset.index;
    if (
      currentSelected === undefined ||
      parseInt(currentSelected, 10) === value
    )
      return;
    else {
      setValue(parseInt(currentSelected, 10));
      if (props.onChange)
        props.onChange.call(null, { value: parseInt(currentSelected, 10) });
    }
  };
  useEffect(() => {
    setValue(0);
  }, [props.range]);
  useEffect(() => {
    if (
      props.indexSelected &&
      props.indexSelected >= 0 &&
      props.indexSelected < props.range.length
    )
      setValue(props.indexSelected);
  }, [props.indexSelected]);
  return (
    <ul className="MemberSelector" onClick={handleChangeIndex}>
      {props.range.map((item, index) => {
        if (index === value)
          return (
            <li
              key={`MemberSelector${item.toString()}${index.toString()}`}
              className="selected"
              data-index={index.toString()}
            >
              {item}
            </li>
          );
        else
          return (
            <li
              key={`MemberSelector${item.toString()}${index.toString()}`}
              data-index={index.toString()}
            >
              {item}
            </li>
          );
      })}
    </ul>
  );
}

export default MemberSelector;
